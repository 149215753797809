<template>
  <div class="container py-5">
    <p class="fw-bold">AMWC MONACO 2023</p>
    <p>時間：2023 03/30-04/01</p>
    <p>地點：MONACO</p>
    <p>
      The #AMWC2023 is happening from March 30th until April 1st. Will we see you there? 
      <br>
      Meet us at booth F1.
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_18.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
